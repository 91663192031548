import {
  BaseStepIdEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'nilos_account',

    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'What Nilos Services would you like to have access to?',
          type: FieldTypeEnum.checkbox,
          isRequired: true,
          isEnabled: true,
          hasHelper: false,
          options: [
            'Crypto custody',
            'Crypto deposits',
            'Crypto withdrawals',
            'Off-ramp (crypto-to-fiat)',
            'On-ramp (fiat-to-crypto)',
            'EUR/GBP IBANs',
            'Virtual Cards (available soon)',
            'Escrow',
          ],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'In what countries do you operate business?',
          type: FieldTypeEnum.country,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          isMulti: true,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Company website',
          type: FieldTypeEnum.url,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Company activity',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: [
            'Cryptocurrencies and cryptoassets',
            'Agriculture, forestry, and fishing',
            'Mining and quarrying',
            'Manufacturing',
            'Electricity, gas, steam, and air conditioning supply',
            'Water supply, sewerage, waste management, and remediation activities',
            'Construction',
            'Wholesale and retail trade; repair of motor vehicles and motorcycles',
            'Transportation and storage',
            'Accommodation and food service activities',
            'Information and communication',
            'Financial and insurance activities',
            'Real estate activities',
            'Professional, scientific, and technical activities',
            'Administrative and support service activities',
            'Public administration and defense; compulsory social security',
            'Education',
            'Human health and social work activities',
            'Arts, entertainment, and recreation',
            'Other service activities',
            'Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use',
            'Activities of extraterritorial organizations and bodies',
          ],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Activity explanation',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Source of Wealth of your Entity',
          type: FieldTypeEnum.checkbox,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: [
            'Sales revenue / Business earnings',
            'Investors funds',
            'Company treasury',
            'Crowdfunding',
            'Investment returns',
            'Loan / Debt Financing',
            'ICO',
            'Grant',
          ],
          hasOtherOption: true,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Last year turnover',
          type: FieldTypeEnum.number,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          addons: {
            right: '€',
          },
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Company assets',
          type: FieldTypeEnum.number,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          addons: {
            right: '€',
          },
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Anticipated source of the funds on your Nilos Account',
          type: FieldTypeEnum.checkbox,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: [
            'Sales revenue / Business earnings',
            'Investors funds',
            'Company treasury',
            'Crowdfunding',
            'Investment returns',
            'Loan / Debt Financing',
            'ICO',
            'Grant',
          ],
          hasOtherOption: true,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Estimated deposits on Nilos account (monthly)',
          type: FieldTypeEnum.number,
          isRequired: true,
          isEnabled: true,
          addons: {
            right: '€',
          },
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Expected monthly outgoing FIAT transactions',
          type: FieldTypeEnum.number,
          isRequired: true,
          isEnabled: true,
          addons: {
            right: '€',
          },
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Do you provide regulated financial services?',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: ['yes', 'no'],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Do you plan to store and or process your clients funds on your Nilos account?',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: ['yes', 'no'],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Are you, or any beneficial owner of the company, considered as a PEP?',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: ['yes', 'no'],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Are you, or any beneficial owner of the company, a US Person?',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: ['yes', 'no'],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Specify who is a PEP and / or a US Person',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          hasHelper: false,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.affiliated_companies_list,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
  {
    id: 'agreement',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'Declaration',
          type: FieldTypeEnum.checkbox,
          isRequired: true,
          isEnabled: true,
          hasHelper: false,
          options: ['I/we understand and agree to this declaration'],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: 'pdf_viewer_terms',
    type: StepTypeEnum.terms_and_conditions,
    config: {
      pdfUrl:
        'https://nilos-public.s3.eu-central-1.amazonaws.com/legal/Modulr+T%26C.pdf',
    },
  },
];
